import React, { Component } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';

export const query = graphql`
  query {
    file(relativePath: { eq: "profile3.jpg" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            subtitle
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`;

export default class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.interestList = React.createRef();
    this.data = props.data;
  }
  render() {
    let posts = this.data.allMarkdownRemark.edges.map((p) => p.node);

    return (
      <Layout>
        <Helmet>
          <body page-name="index" />
        </Helmet>
        <main>
          <p>
            I’m a product-focused developer and all-around tech nerd
            specializing in web application development.
          </p>

          <p>
            Currently working to make the world a safer place as a Senior
            Software Engineer at{' '}
            <a rel="noreferrer noopener" href="https://vectra.ai">
              Vectra&nbsp;AI
            </a>
            .
          </p>

          <p>
            I lead <Link to="/workshops">workshops</Link> on topics in
            browser-focused web app development, CSS, modern JavaScript, and
            source code management with git. If your organization would like
            hands-on training to learn or refresh skills in these areas,{' '}
            <Link to="/contact">reach out</Link> to see how I may be able to
            help.
          </p>

          <br />

          <h2>recent writings</h2>
          {posts.map(({ frontmatter: fm }, i) => (
            <div className="post" key={i}>
              <h3>
                <Link to={fm.path}>{fm.title}</Link>
              </h3>
              <div className="subtitle">{fm.subtitle}</div>
              <div className="date">{fm.date}</div>
            </div>
          ))}
          <p>
            <Link to="writing">more…</Link>
          </p>
        </main>
      </Layout>
    );
  }
}
